import React from "react";
import { Link, graphql } from "gatsby";

import { Bio } from "../components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { rhythm, scale } from "../utils/typography";

import styles from "../assets/scss/pages.index.module.scss";

class BlogIndex extends React.Component {
    render() {
        const { data, location } = this.props;
        const siteTitle = data.site.siteMetadata.title;
        const posts = data.allMarkdownRemark.edges;

        return (
            <Layout location={location} title={siteTitle}>
                <SEO title="Home" />
                <Bio location={location} />
                {posts.map(({ node }) => {
                    const title = node.frontmatter.title || node.fields.slug;
                    const backgroundChar = title[0];
                    return (
                        <article key={node.fields.slug}>
                            <header style={{ position: "relative" }}>
                                <h2
                                    style={{
                                        fontFamily: "Josefin Sans",
                                        marginBottom: rhythm(1 / 4),
                                        ...scale(1.5),
                                    }}
                                >
                                    <Link
                                        style={{ boxShadow: `none` }}
                                        className={styles.link}
                                        to={node.fields.slug}
                                    >
                                        {title}
                                    </Link>
                                </h2>
                                <small>
                                    {node.frontmatter.date} /{" "}
                                    {node.fields.readingTime.text}
                                </small>
                                <div
                                    className={styles.postLetter}
                                    style={{
                                        ...scale(2.75),
                                        fontFamily: "Josefin Sans",
                                    }}
                                >
                                    {backgroundChar}
                                </div>
                            </header>
                            <section style={{ marginTop: rhythm(0.5) }}>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            node.frontmatter.description ||
                                            node.excerpt,
                                    }}
                                />
                            </section>
                        </article>
                    );
                })}
            </Layout>
        );
    }
}

export default BlogIndex;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
            edges {
                node {
                    excerpt
                    fields {
                        slug
                        readingTime {
                            text
                        }
                    }
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        title
                        description
                    }
                }
            }
        }
    }
`;
